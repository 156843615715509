import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { AnyFunction, Any } from '@/interfaces'
import { storage } from './storage'

interface ResponseData {
  success: boolean
  result: Any
  errorCode: string | number
  message: string
}

type RequestError = Any

type HttpRequestParams = {[key: string]: Any}

interface HttpRequestConfig {
  isCustomErrorHandler: boolean
}

interface AxiosConfig {
  baseUrl: string
  timeout?: number
  postHeaders?: {[key: string]: string}
  errorHandler?: AnyFunction
  reqInterceptor?: AnyFunction
  resInterceptor?: AnyFunction
}

axios.defaults.baseURL = '/api'
axios.defaults.timeout = 10000 // 10秒
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

export class Http {
  axiosConfig?: AxiosConfig
  axiosInstance: AxiosInstance

  constructor (config?: AxiosConfig) {
    this.axiosConfig = config
    this.axiosInstance = axios.create()
    this.initAxiosInstance()
  }

  private initAxiosInstance() {
    if (this.axiosConfig?.baseUrl) {
      this.axiosInstance.defaults.baseURL = this.axiosConfig.baseUrl
    }
    if (this.axiosConfig?.timeout) {
      this.axiosInstance.defaults.timeout = this.axiosConfig.timeout
    }
    if (this.axiosConfig?.postHeaders) {
      this.axiosInstance.defaults.headers.post = this.axiosConfig.postHeaders
    }
    this.axiosInstance.interceptors.request.use((config): AxiosRequestConfig<Any> => {
      const token = storage.get('accessToken')
      if (token && config.headers) {
        config.headers.token = String(token)
      }
      if (this.axiosConfig?.reqInterceptor) {
        config = this.axiosConfig?.reqInterceptor?.(config)
      }
      return config
    }, (err) => {
      return err
    })
    if (this.axiosConfig?.resInterceptor) {
      this.axiosInstance.interceptors.response.use((res) => {
        res = this.axiosConfig?.resInterceptor?.(res)
        return res
      })
    }
  }

  get(url: string, config?: HttpRequestConfig) : Promise<any> {
    return new Promise((resolve: AnyFunction, reject: AnyFunction) => {
      this.axiosInstance.get(url).then((res) => {
        const data = res.data
        if (data?.success) {
          resolve(data.result)
        } else {
          this.handleError(resolve, reject, data, config)
        }
      }).catch((err) => {
        this.handleError(resolve, reject, err, config)
      })
    })
  }

  post (url: string, params?: HttpRequestParams, config?: HttpRequestConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post(url, params).then((res) => {
        const data = res.data
        if (data?.success) {
          resolve(data.result)
        } else {
          this.handleError(resolve, reject, data, config)
        }
      }).catch((err) => {
        this.handleError(resolve, reject, err, config)
      })
    })
  }

  put(url: string, params?: HttpRequestParams, config?: HttpRequestConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.put(url, params).then((res) => {
        const data = res.data
        if (data?.success) {
          resolve(data.result)
        } else {
          this.handleError(resolve, reject, data, config)
        }
      }).catch((err) => {
        this.handleError(resolve, reject, err, config)
      })
    })
  }

  upload(url: string, file: Any, config?: HttpRequestConfig): Promise<Partial<ResponseData>> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post(url, file, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((res) => {
        const data = res.data
        if (data?.success) {
          resolve(data)
        } else {
          this.handleError(resolve, reject, data, config)
        }
      }).catch((err) => {
        this.handleError(resolve, reject, err, config)
      })
    })
  }

  download(url: string): void {
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = url
    iframe.onload = () => {
      document.body.removeChild(iframe)
    }
    document.body.appendChild(iframe)
  }

  handleError(resolve: Any, reject: Any, err: ResponseData | RequestError, config?: HttpRequestConfig): void {
    if (config?.isCustomErrorHandler) {
      reject(err)
    } else {
      if (this.axiosConfig?.errorHandler) {
        this.axiosConfig.errorHandler(resolve, reject, err, config)
      }
      resolve(null)
    }
  }
}

export const http = new Http({
  baseUrl: 'http://127.0.0.1:3000/api/v1'
})
