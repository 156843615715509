export function getParams(values) {
  if (!values) {
    return null
  }
  const keys = Object.keys(values)
  if (keys.length === 0) {
    return null
  }
  const params = {}
  keys.forEach((key) => {
    if (values[key]) {
      params[key] = values[key]
    }
  })

  return Object.keys(params).length > 0 ? params : null
}
