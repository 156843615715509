import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "images",
  id: "images"
};
var _hoisted_2 = ["href"];
var _hoisted_3 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, function (item, i) {
    return _openBlock(), _createElementBlock("a", {
      class: "item",
      key: i,
      href: item.url
    }, [_createElementVNode("img", {
      class: "image",
      src: item.url,
      alt: item.title || ''
    }, null, 8, _hoisted_3)], 8, _hoisted_2);
  }), 128))]);
}