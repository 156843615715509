import { createVNode as _createVNode, isVNode as _isVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.object.to-string.js";
import { defineComponent } from 'vue';
import { Setting, Menu, HomeFilled, DataAnalysis } from '@element-plus/icons-vue';

function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    return function () {
      var _slot;

      return _createVNode(_resolveComponent("el-icon"), null, _isSlot(_slot = mapIcon(props.icon)) ? _slot : {
        default: function _default() {
          return [_slot];
        }
      });
    };
  }
});

function mapIcon(icon) {
  switch (icon) {
    case 'setting':
      return _createVNode(Setting, null, null);

    case 'menu':
      return _createVNode(Menu, null, null);

    case 'home-filled':
      return _createVNode(HomeFilled, null, null);

    case 'data-analysis':
      return _createVNode(DataAnalysis, null, null);
  }

  return null;
}