import { defineComponent, onMounted } from 'vue';
import { createWatermark } from './watermark';
export default defineComponent({
  setup: function setup() {
    onMounted(function () {
      createWatermark('引言率_2022-11-11 11:38:88');

      window.onresize = function () {
        createWatermark('引言率_2022-11-11 11:48:88');
      };
    });
  },
  methods: {}
});