import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Home',
  setup: function setup() {
    return {
      images: [{
        url: '/img/works/1.jpg'
      }, {
        url: '/img/works/2.jpg'
      }, {
        url: '/img/works/3.jpg'
      }, {
        url: '/img/works/4.jpg'
      }, {
        url: '/img/works/5.jpg'
      }, {
        url: '/img/works/6.jpg'
      }, {
        url: '/img/works/7.jpg'
      }, {
        url: '/img/works/8.jpg'
      }, {
        url: '/img/works/9.jpg'
      }]
    };
  }
});