import { ref, ExtractPropTypes } from 'vue'
import { renderComponent } from '@/utils/render'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog.vue'

export const dialogProps = {
  isShow: Boolean,
  onDestroy: Function, // 组件消失时，移除组件实例
  onConfirm: Function, // 组件调用成功
  onCancel: Function, // 组件调用失败
  title: {
    type: String,
    default: '提示'
  },
  message: String,
  confirmBtnText: {
    type: String,
    default: '确定'
  }
}

type DialogProps = Partial<ExtractPropTypes<typeof dialogProps>>

export class Dialog {
  static open(props: DialogProps) {
    return new Promise((resolve, reject) => {
      renderComponent(ConfirmDialog, {
        ...props,
        isShow: ref(true)
      })
    })
  }
}
