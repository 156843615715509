import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3d403418"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "home"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_images = _resolveComponent("a-images");

  var _component_a_panel = _resolveComponent("a-panel");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_panel, {
    title: "个人作品展示"
  }, {
    content: _withCtx(function () {
      return [_createVNode(_component_a_images, {
        list: _ctx.images
      }, null, 8, ["list"])];
    }),
    _: 1
  })]);
}