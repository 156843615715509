import { App } from 'vue'
import { Http } from '@/utils/http'

const http = new Http({
  baseUrl: 'http://127.0.0.1:3000/api/v1'
})

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $http: typeof http
  }
}

export function initVue(app: App) {
  app.config.globalProperties.$http = http
}
