import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    type: String,
    label: String,
    prop: String
  },
  setup: function setup() {
    var getValue = function getValue() {//
    };

    return {
      getValue: getValue
    };
  }
});