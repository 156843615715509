import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "form"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_form_item = _resolveComponent("el-form-item");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form_item, {
    label: _ctx.label,
    prop: _ctx.prop
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3
  }, 8, ["label", "prop"])]);
}