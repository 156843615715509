export const appModule = {
  namespaced: true,
  state: {
    userInfo: null,
    isNavCollapsed: false,
    activeNavItem: null,
    navPathItems: null,
    tabs: null,
    contextmenuTabDomId: null
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
    },
    setNavCollapsed(state, data) {
      state.isNavCollapsed = data
    },
    setActiveNavItem(state, data) {
      state.activeNavItem = data
    },
    setNavPathItems(state, data) {
      state.navPathItems = data
    },
    setTabs(state, data) {
      state.tabs = data
    },
    setContextmenuTabDomId(state, data) {
      state.contextmenuTabDomId = data
    }
  }
}
