import { defineComponent, ref, watchEffect } from 'vue';
import { WarningFilled } from '@element-plus/icons-vue';
import { dialogProps } from '@/components/dialog/Dialog';
export default defineComponent({
  components: {
    WarningFilled: WarningFilled
  },
  props: dialogProps,
  setup: function setup(props) {
    var isShowDialog = ref(false);
    var formRef = ref();
    watchEffect(function () {
      isShowDialog.value = props.isShow;
    });
    return {
      formRef: formRef,
      isShowDialog: isShowDialog
    };
  },
  methods: {
    open: function open() {
      this.isShowDialog = true;
    },
    close: function close() {
      this.isShowDialog = false;
    },
    handleClickCancel: function handleClickCancel() {
      if (this.onCancel) {
        this.onCancel();
      } else {
        this.$emit('cancel');
      }

      this.close();
    },
    handleClickConfirm: function handleClickConfirm() {
      if (this.onConfirm) {
        this.onConfirm();
      } else {
        this.$emit('confirm');
      }

      this.close();
    }
  }
});