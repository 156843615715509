import { isVNode as _isVNode, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import { useNav, useState, useTabs } from '@/hooks';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { NAV_LIST as navList, NAV_MAP } from '@/data/nav';

function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}

export default defineComponent({
  emits: ['select'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useState = useState('appModule', ['isNavCollapsed']),
        isNavCollapsed = _useState.isNavCollapsed;

    var router = useRouter();

    var _useTabs = useTabs(),
        addTab = _useTabs.addTab;

    var _useNav = useNav(),
        activeNavCode = _useNav.activeNavCode,
        setActiveNavItem = _useNav.setActiveNavItem,
        setNavPathItemsByPathList = _useNav.setNavPathItemsByPathList;

    var handleSelect = function handleSelect(index, indexPath) {
      var _item$meta;

      var item = NAV_MAP[index];

      if (item !== null && item !== void 0 && (_item$meta = item.meta) !== null && _item$meta !== void 0 && _item$meta.routerPath) {
        addTab(item);
        router.push(item.meta.routerPath);
        setActiveNavItem(item);
        setNavPathItemsByPathList(indexPath);
      }

      emit('select', {
        index: index,
        indexPath: indexPath
      });
    };

    return {
      isCollapsed: isNavCollapsed,
      handleSelect: handleSelect,
      activeNavCode: activeNavCode
    };
  },
  render: function render(props) {
    var _slot;

    if (!navList || navList.length === 0) {
      return null;
    }

    return _createVNode(_resolveComponent("el-menu"), {
      "onSelect": props.handleSelect,
      "collapse": props.isCollapsed,
      "backgroundColor": '#17233d',
      "textColor": '#fff',
      "activeTextColor": '#fff',
      "defaultActive": props.activeNavCode
    }, _isSlot(_slot = navList.map(function (item) {
      return _createVNode(_resolveComponent("a-nav-item"), {
        "item": item,
        "key": item.code
      }, null);
    })) ? _slot : {
      default: function _default() {
        return [_slot];
      }
    });
  }
});