import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3a90d622"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "select"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");

  var _component_el_select = _resolveComponent("el-select");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_select, {
    modelValue: _ctx.selectValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.selectValue = $event;
    }),
    clearable: "",
    placeholder: _ctx.placeholder,
    "fit-input-width": true,
    onChange: _ctx.handleChange
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (item) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: item.code,
          label: item.name,
          value: item.code
        }, null, 8, ["label", "value"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["modelValue", "placeholder", "onChange"])]);
}