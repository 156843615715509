import { h, Component, render } from 'vue'

export const renderComponent = (CmpConstructor: Component, props: Record<string, any>) => {
  const container = document.createElement('div')

  props.handleDestroy = () => {
    render(null, container)
  }

  const vnode = h(CmpConstructor as any, props)

  render(vnode, container)

  if (container.firstElementChild) {
    document.body.appendChild(container.firstElementChild)
  }
}
