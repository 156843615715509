import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-22808c7e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "tabs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");

  var _component_el_tabs = _resolveComponent("el-tabs");

  var _component_a_context_menu = _resolveComponent("a-context-menu");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
    type: "card",
    modelValue: _ctx.tabCode,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.tabCode = $event;
    }),
    onTabRemove: _ctx.removeTab,
    onTabClick: _ctx.clickTab,
    onContextmenu: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
      return _ctx.openContextMenu($event);
    }, ["prevent"]))
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, function (item) {
        return _openBlock(), _createBlock(_component_el_tab_pane, {
          key: item.code,
          label: item.name,
          name: item.code,
          closable: item.meta.closable
        }, null, 8, ["label", "name", "closable"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["modelValue", "onTabRemove", "onTabClick"]), (_openBlock(), _createBlock(_Teleport, {
    to: "#app"
  }, [_createVNode(_component_a_context_menu, {
    isShow: _ctx.contextMenuData.isShow,
    left: _ctx.contextMenuData.left,
    top: _ctx.contextMenuData.top,
    onCloseContextMenu: _ctx.closeContextMenu
  }, null, 8, ["isShow", "left", "top", "onCloseContextMenu"])]))]);
}