import { computed } from 'vue'
import { useState, useMutations, useNav } from '.'
import { NAV_MAP, HOME_NAV_ITEM } from '@/data'

export function useTabs() {
  const { setActiveNavItemByIndex } = useNav()
  const { tabs, activeNavItem } = useState('appModule', ['tabs', 'activeNavItem'])
  const { setTabs, setActiveNavItem } = useMutations('appModule', ['setTabs', 'setActiveNavItem'])

  const addTab = (tab) => {
    const { include } = isTabAdded(tabs.value, tab)
    if (!include) {
      const oldTabs = tabs.value || []
      setTabs([...oldTabs, tab])
    }
  }

  const removeTabByCode = (code) => {
    const { index } = isTabAdded(tabs.value, { code })
    const prevTab = tabs.value[index - 1]
    const prevTabs = index > 0 ? tabs.value.slice(0, index) : []
    const nextTabs = (index + 1 <= tabs.value.length) ? tabs.value.slice(index + 1, tabs.value.length) : []

    // 如果当前Tab和点击关闭的tab一致，则需要更新NavItem
    if (activeNavItem.value.code === code) {
      setActiveNavItemByIndex(prevTab.code)
    }
    setTabs([...prevTabs, ...nextTabs])
  }

  // 页面第一次加载时，初始化tabs
  const initTabsByPath = (path) => {
    const keys = Object.keys(NAV_MAP)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (NAV_MAP[key].meta.routerPath === path) {
        const navItem = NAV_MAP[key]
        if (navItem.code === HOME_NAV_ITEM.code) {
          setTabs([HOME_NAV_ITEM])
        } else {
          setTabs([HOME_NAV_ITEM, navItem])
        }
        break
      }
    }
  }

  const activeTabCode = computed(() => {
    return activeNavItem && activeNavItem.value ? activeNavItem.value.code : null
  })

  return {
    tabs,
    setTabs,
    addTab,
    removeTabByCode,
    isTabAdded,
    activeTabCode,
    setActiveNavItem,
    initTabsByPath
  }
}

function isTabAdded(tabs, tab) {
  const res = {
    include: false,
    index: -1
  }
  if (!tabs || tabs.length === 0) {
    return res
  }
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].code === tab.code) {
      return {
        include: true,
        index: i
      }
    }
  }
  return res
}
