import { defineComponent, onMounted } from 'vue';
import { useNav, useTabs } from '@/hooks';
export default defineComponent({
  setup: function setup() {
    var _useNav = useNav(),
        setActiveNavItemByPath = _useNav.setActiveNavItemByPath;

    var _useTabs = useTabs(),
        initTabsByPath = _useTabs.initTabsByPath;

    var path = window.location.pathname;

    var initNav = function initNav() {
      setActiveNavItemByPath(path);
    };

    var initTabs = function initTabs() {
      initTabsByPath(path);
    };

    onMounted(function () {
      initNav();
      initTabs(); // document.documentElement.style.setProperty('--el-color-primary', 'green')
    });
  }
});