import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'default-passive-events' // Chrome51，优化页面滚动性能，https://blog.csdn.net/dj0379/article/details/52883315
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { initComponents, initVue } from './init'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)

initComponents(app)
initVue(app)

app.use(store).use(router).use(ElementPlus, {
  size: 'small',
  zIndex: 3000,
  locale: zhCn
}).mount('#app')
