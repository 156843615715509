import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e3b7401a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_header = _resolveComponent("a-header");

  var _component_router_view = _resolveComponent("router-view");

  var _component_a_footer = _resolveComponent("a-footer");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_header), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)]), _createVNode(_component_a_footer)]);
}