import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cb24a7b6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "panel"
};
var _hoisted_2 = {
  class: "panel-title"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = {
  class: "right"
};
var _hoisted_5 = {
  class: "panel-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "title-right", {}, undefined, true)])]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])]);
}