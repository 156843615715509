import "core-js/modules/es.number.constructor.js";
import { defineComponent, ref, computed } from 'vue';
export default defineComponent({
  emits: ['save', 'cancel'],
  props: {
    title: String,
    width: {
      type: [String, Number],
      default: '50%'
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    formData: Object,
    rules: Object
  },
  setup: function setup(props) {
    var mode = ref('create');
    var isShow = ref(false);
    var formRef = ref();
    var dialogTitle = computed(function () {
      if (mode.value === 'create') {
        return "".concat(props.title, " - \u65B0\u589E");
      } else if (mode.value === 'edit') {
        return "".concat(props.title, " - \u4FEE\u6539");
      }

      return props.title;
    });
    return {
      mode: mode,
      isShow: isShow,
      formRef: formRef,
      dialogTitle: dialogTitle
    };
  },
  methods: {
    open: function open(params) {
      this.isShow = true;
      this.mode = params.mode; // 清除数据验证报红

      if (this.$refs.formRef && this.$refs.formRef.clearValidate) {
        this.$refs.formRef.clearValidate();
      }
    },
    close: function close() {
      this.isShow = false;
    },
    handleClickCancel: function handleClickCancel() {
      this.$emit('cancel');
      this.close();
    },
    handleClickSave: function handleClickSave() {
      var _this = this;

      this.$refs.formRef.validate(function (valid) {
        if (valid) {
          _this.$emit('save', _this.formData);
        }
      });
    }
  }
});