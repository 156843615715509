import "core-js/modules/es.number.constructor.js";
import { defineComponent, ref, watchEffect } from 'vue';
var defaultPageNo = 1;
var defaultPageSize = 20;
export default defineComponent({
  props: {
    total: Number,
    pageSize: Number,
    pageNo: Number
  },
  setup: function setup(props) {
    var currentPage = ref(defaultPageNo);
    var size = ref(defaultPageSize);
    watchEffect(function () {
      currentPage.value = props.pageNo || defaultPageNo;
      size.value = props.pageSize || defaultPageSize;
    });
    return {
      currentPage: currentPage,
      size: size
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange(params) {
      this.$emit('change', {
        page: this.currentPage || defaultPageNo,
        size: params
      });
    },
    handleCurrentChange: function handleCurrentChange(params) {
      this.$emit('change', {
        pageNo: params,
        pageSize: this.pageSize || defaultPageSize
      });
    },
    getPaginationParams: function getPaginationParams() {
      return {
        pageNo: this.currentPage || defaultPageNo,
        pageSize: this.pageSize || defaultPageSize
      };
    }
  }
});