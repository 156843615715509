import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_pagination = _resolveComponent("el-pagination");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_pagination, {
    currentPage: _ctx.currentPage,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.currentPage = $event;
    }),
    "page-sizes": [10, 20, 50, 100],
    "page-size": _ctx.size,
    layout: "total, sizes, prev, pager, next, jumper",
    total: _ctx.total,
    onSizeChange: _ctx.handleSizeChange,
    onCurrentChange: _ctx.handleCurrentChange
  }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])]);
}