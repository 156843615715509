import { resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.fixed.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { defineComponent, reactive, ref } from 'vue';
import { Edit, Delete } from '@element-plus/icons-vue';
export default defineComponent({
  props: {
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    data: Object,
    isShowSelectionColumn: {
      type: Boolean,
      default: true
    },
    isShowIndexColumn: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: [String, Number],
    columnsFixed: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {
    var tableRef = ref(null);
    var sort = reactive({});
    var selections = reactive([]);
    return {
      sort: sort,
      selections: selections,
      tableRef: tableRef
    };
  },
  render: function render(props) {
    var _this = this;

    if (props.columns.length === 0) {
      return null;
    }

    var deleteSlots = {
      reference: function reference() {
        return _createVNode(_Fragment, null, [_createVNode(_resolveComponent("el-button"), {
          "type": "text",
          "size": "small",
          "icon": _createVNode(Delete, null, null),
          "onClick": function onClick(e) {
            e.stopPropagation();
          }
        }, {
          default: function _default() {
            return [_createTextVNode("\u5220\u9664")];
          }
        })]);
      }
    };
    var slots = {
      default: function _default(scope) {
        return _createVNode("div", null, [_createVNode(_resolveComponent("el-button"), {
          "type": "text",
          "size": "small",
          "icon": _createVNode(Edit, null, null),
          "onClick": function onClick(e) {
            e.stopPropagation();
            return _this.editRecord(scope, e);
          }
        }, {
          default: function _default() {
            return [_createTextVNode("\u4FEE\u6539")];
          }
        }), _createVNode(_resolveComponent("el-popconfirm"), {
          "confirmButtonText": '删除',
          "cancelButtonText": '取消',
          "title": '确认删除本条数据？',
          "iconColor": '#ffba00',
          "onConfirm": function onConfirm(e) {
            return _this.deleteRecord(scope, e);
          }
        }, deleteSlots)]);
      }
    };
    return _createVNode("div", {
      "class": "table"
    }, [_createVNode(_resolveComponent("el-table"), {
      "ref": function ref(el) {
        props.tableRef = el;
      },
      "data": props.data,
      "width": this.width,
      "height": this.height ? this.height : null,
      "border": true,
      "highlightCurrentRow": true,
      "headerCellClassName": this.handleHeaderCellClass,
      "onSelectionChange": this.handleSelectionChange,
      "onSortChange": this.handleSortChange,
      "onRowClick": this.handleClickRow
    }, {
      default: function _default() {
        return [props.isShowSelectionColumn ? _createVNode(_resolveComponent("el-table-column"), {
          "type": "selection",
          "width": "55",
          "fixed": props.columnsFixed || false
        }, null) : null, props.isShowIndexColumn ? _createVNode(_resolveComponent("el-table-column"), {
          "type": "index",
          "width": "50",
          "label": '序号',
          "fixed": props.columnsFixed || false
        }, null) : null, props.columns.map(function (item) {
          return _createVNode(_resolveComponent("el-table-column"), {
            "label": item.label,
            "property": item.property,
            "width": item.width || 100,
            "fixed": item.fixed || false,
            "sortable": item.sortable ? false : 'custom',
            "resizable": true,
            "showOverflowTooltip": true
          }, null);
        }), _createVNode(_resolveComponent("el-table-column"), {
          "fixed": "right",
          "label": "操作",
          "width": "120"
        }, slots)];
      }
    })]);
  },
  methods: {
    handleSelectionChange: function handleSelectionChange(selections) {
      this.selections = selections;
      this.$emit('selection-change', selections);
    },
    editRecord: function editRecord(scope, e) {
      this.$emit('edit', scope.row);
    },
    deleteRecord: function deleteRecord(scope, e) {
      this.$emit('delete', scope.row);
    },
    handleClickRow: function handleClickRow(row) {
      var tableRef = this.tableRef;

      if (tableRef && tableRef.toggleRowSelection) {
        tableRef.toggleRowSelection(row);
      }
    },
    handleSortChange: function handleSortChange(_ref) {
      var column = _ref.column,
          prop = _ref.prop,
          order = _ref.order;

      // FIXME: 排序不可取消
      if (!prop) {
        this.sort = reactive({});
      }

      if (order) {
        this.sort[prop] = order;
      } else {
        if (this.sort[prop]) {
          delete this.sort[prop];
        }
      }

      this.$emit('sort');
    },
    handleHeaderCellClass: function handleHeaderCellClass(_ref2) {
      var column = _ref2.column;
      column.order = this.sort[column.property] || '';
    },
    getSortParams: function getSortParams() {
      var _this2 = this;

      var params = null;
      var keys = Object.keys(this.sort);

      if (keys.length > 0) {
        params = {};
        keys.forEach(function (key) {
          params[key] = _this2.sort[key] === 'descending' ? 'desc' : 'asc';
        });
      }

      return params;
    },
    getSelections: function getSelections() {
      return this.selections;
    }
  }
});