import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  setup: function setup(props) {
    var router = useRouter();
    var errorMsg = computed(function () {
      var msg = '';

      switch (props.type) {
        case '401':
          msg = '401，禁止访问该页面';
          break;

        case '403':
          msg = '403，无权限访问该页面';
          break;

        case '404':
        default:
          msg = '404，未找到该页面';
      }

      return msg;
    });
    var isShowLoginBtn = computed(function () {
      if (props.type === '401') {
        return true;
      }

      return false;
    });
    var isShowHomeBtn = computed(function () {
      if (props.type === '401') {
        return false;
      }

      return true;
    });
    return {
      router: router,
      errorMsg: errorMsg,
      isShowLoginBtn: isShowLoginBtn,
      isShowHomeBtn: isShowHomeBtn
    };
  },
  methods: {
    handleClickHome: function handleClickHome() {
      this.router.push('/');
    },
    handleClickLogin: function handleClickLogin() {
      this.router.push('/login');
    },
    handleClickBack: function handleClickBack() {
      if (this.type === '403') {
        this.router.go(-2);
      } else {
        this.router.go(-1);
      }
    }
  }
});