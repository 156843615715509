const id = 'watermark-1'

export function createWatermark(text) {
  const oldWatermark = document.getElementById(id)
  // 水印已创建时，删除之前已创建的水印，避免重复创建
  if (oldWatermark) {
    document.body.removeChild(oldWatermark)
  }
  const canv = document.createElement('canvas')
  canv.width = 250
  canv.height = 250
  const ctx = canv.getContext('2d')
  if (ctx) {
    // 旋转角度
    ctx.rotate(6)
    ctx.font = '14px Arial'
    ctx.fillStyle = 'rgba(200, 200, 200, 0.25)'
    ctx.textAlign = 'left'
    ctx.textBaseline = 'middle'
    ctx.fillText(text, canv.width / 10, canv.height / 2)
  }

  const div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '0'
  div.style.left = '0'
  div.style.position = 'fixed'
  div.style.zIndex = '10000'
  div.style.width = document.documentElement.clientWidth + 'px'
  div.style.height = document.documentElement.clientHeight + 'px'
  div.style.background = `url(${canv.toDataURL('image/png')}) left top repeat`

  document.body.appendChild(div)
}
