import { defineComponent, onMounted } from 'vue';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
export default defineComponent({
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {
    onMounted(function () {
      if (props.list && props.list.length > 0) {
        lightGallery(document.getElementById('images'), {
          plugins: [lgZoom, lgThumbnail],
          speed: 500
        });
      }
    });
  }
});