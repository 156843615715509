import "core-js/modules/es.function.name.js";
import { useTabs, useNav, useMutations } from '@/hooks';
import { defineComponent, reactive, watchEffect } from 'vue';
export default defineComponent({
  setup: function setup() {
    var _useMutations = useMutations('appModule', ['setContextmenuTabDomId']),
        setContextmenuTabDomId = _useMutations.setContextmenuTabDomId;

    var _useTabs = useTabs(),
        tabs = _useTabs.tabs,
        activeTabCode = _useTabs.activeTabCode,
        removeTabByCode = _useTabs.removeTabByCode;

    var _useNav = useNav(),
        setActiveNavItemByIndex = _useNav.setActiveNavItemByIndex;

    var contextMenuData = reactive({
      isShow: false,
      left: 0,
      top: 0
    });
    var tabCode = '';
    watchEffect(function () {
      tabCode = activeTabCode.value;
    });

    var removeTab = function removeTab(code) {
      removeTabByCode(code);
    };

    var clickTab = function clickTab(item) {
      setActiveNavItemByIndex(item.props.name);
    };

    var openContextMenu = function openContextMenu(e, b) {
      setContextmenuTabDomId(e.target.id); // 防止系统默认菜单弹出

      e.preventDefault();
      contextMenuData.isShow = true;
      contextMenuData.left = e.clientX;
      contextMenuData.top = e.clientY + 10;
    };

    var closeContextMenu = function closeContextMenu() {
      contextMenuData.isShow = false;
    };

    return {
      tabs: tabs,
      removeTab: removeTab,
      clickTab: clickTab,
      openContextMenu: openContextMenu,
      contextMenuData: contextMenuData,
      closeContextMenu: closeContextMenu,
      tabCode: tabCode
    };
  }
});