import { NavItem } from '@/components/nav/types'

export const NAV_LIST: NavItem[] = [{
  id: '1',
  code: '1',
  name: '我的工作台',
  icon: 'home-filled',
  meta: {
    routerPath: '/',
    cache: true,
    closable: false
  },
  children: null
}, {
  id: '2',
  code: '2',
  name: '模块管理',
  icon: 'menu',
  meta: null,
  children: [{
    id: '2-1',
    code: '2-1',
    name: '文章管理',
    meta: {
      routerPath: '/modules/article',
      cache: true,
      closable: true
    },
    children: null
  }, {
    id: '2-2',
    code: '2-2',
    name: '消息管理',
    meta: {
      routerPath: '/modules/message',
      cache: true,
      closable: true
    },
    children: null
  }]
}, {
  id: '3',
  code: '3',
  name: '数据统计',
  icon: 'data-analysis',
  meta: null,
  children: [{
    id: '3-1',
    code: '3-1',
    name: '页面访问统计',
    meta: {
      routerPath: '/statistics/visit',
      cache: true,
      closable: true
    },
    children: null
  }]
}, {
  id: '4',
  code: '4',
  name: '系统设置',
  icon: 'setting',
  meta: null,
  children: [{
    id: '4-1',
    code: '4-1',
    name: '账号管理',
    meta: {
      routerPath: '/settings/user',
      cache: true,
      closable: true
    },
    children: null
  }, {
    id: '4-2',
    code: '4-2',
    name: '角色管理',
    meta: {
      routerPath: '/settings/role',
      cache: true,
      closable: true
    },
    children: null
  }, {
    id: '4-3',
    code: '4-3',
    name: '功能权限管理',
    meta: {
      routerPath: '/settings/func-authority',
      cache: true,
      closable: true
    },
    children: null
  }, {
    id: '4-4',
    code: '4-4',
    name: '数据权限管理',
    meta: {
      routerPath: '/settings/data-authority',
      cache: true,
      closable: true
    },
    children: null
  }]
}]

export const NAV_MAP = buildNavMap(NAV_LIST)
export const HOME_NAV_ITEM = getHomeNavItem('1', NAV_MAP)

function buildNavMap(navList: NavItem[]) {
  const map = {}

  for (let i = 0; i < navList.length; i++) {
    const item = navList[i]
    if (!item.meta) {
      item.meta = {}
    }
    item.meta.navPathList = [item.code]
    map[item.code] = item
    if (item && item.children && item.children.length > 0) {
      for (let j = 0; j < item.children.length; j++) {
        const subItem = item.children[j]
        if (!subItem.meta) {
          subItem.meta = {}
        }
        // 跟踪nav路径列表
        subItem.meta.navPathList = [item.code, subItem.code]
        map[subItem.code] = subItem
      }
    }
  }

  return map
}

function getHomeNavItem(code, navMap) {
  const keys = Object.keys(navMap)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (key === code) {
      return navMap[key]
    }
  }
}
