import { App } from 'vue'

/**
 * 注册本地组件，components目录
 */
export function initComponents<HostElement>(app: App<HostElement>): void {
  const requireComponents = require.context('../components', true, /[A-Za-z0-9_-]+\.vue$|[A-Za-z0-9_-]+\.tsx$/)

  requireComponents.keys().forEach((filename: string) => {
    const cmpConfig = requireComponents(filename)
    const regex = filename.includes('.vue') ? /\w+\/(\w+)\.vue$/ : /\w+\/(\w+)\.tsx$/
    const matchList = filename.match(regex)
    if (matchList && matchList[1]) {
      app.component('A' + matchList[1], cmpConfig.default || cmpConfig)
    }
  })
}
