import "core-js/modules/es.object.to-string.js";
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    formData: Object,
    labelWidth: {
      type: String,
      default: '100px'
    }
  },
  setup: function setup() {
    var formRef = ref(null);
    return {
      formRef: formRef
    };
  },
  methods: {
    getFormValues: function getFormValues() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.$refs.formRef.validate(function (valid) {
          if (!valid) {
            reject(new Error('表单验证失败'));
          }

          return resolve(_this.formData);
        });
      });
    },
    resetFields: function resetFields() {
      this.$refs.formRef.resetFields();
    }
  }
});