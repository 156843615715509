import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { NAV_MAP } from '@/data'
import { useState, useMutations } from '.'

export function useNav() {
  const router = useRouter()
  const { activeNavItem, navPathItems } = useState('appModule', ['activeNavItem', 'navPathItems'])
  const { setActiveNavItem, setNavPathItems } = useMutations('appModule', ['setTabs', 'setActiveNavItem', 'setNavPathItems'])

  // 将路径数组映射为NavItem数组
  const setNavPathItemsByPathList = (pathList) => {
    const pathItems = pathList.map((item) => {
      return NAV_MAP[item]
    })
    setNavPathItems(pathItems)
  }

  // 通过NavItem的code设置当前NavItem
  const setActiveNavItemByIndex = (index) => {
    const navItem = NAV_MAP[index]
    setActiveNavItem(navItem)
    setNavPathItemsByPathList(navItem.meta.navPathList)
    router.push(navItem.meta.routerPath)
  }

  // 通过路由path设置当前NavItem
  const setActiveNavItemByPath = (path) => {
    const keys = Object.keys(NAV_MAP)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (NAV_MAP[key].meta.routerPath === path) {
        const navItem = NAV_MAP[key]
        setActiveNavItem(navItem)
        setNavPathItemsByPathList(navItem.meta.navPathList)
        break
      }
    }
  }

  const activeNavCode = computed(() => {
    return activeNavItem && activeNavItem.value ? activeNavItem.value.code : null
  })

  return {
    activeNavItem,
    navPathItems,
    activeNavCode,
    setActiveNavItem,
    setActiveNavItemByIndex,
    setActiveNavItemByPath,
    setNavPathItems,
    setNavPathItemsByPathList
  }
}
