import _objectSpread from "/Users/bugong/Documents/github/quzhifang/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { resolveDirective as _resolveDirective, Fragment as _Fragment, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    item: {
      type: Object
    }
  },
  setup: function setup(props) {
    return function () {
      var item = props.item;

      if (!item) {
        return null;
      }

      if (!item.children || item.children.length === 0) {
        return _createVNode(_resolveComponent("el-menu-item"), {
          "index": item.code
        }, {
          default: function _default() {
            return [item.icon ? _createVNode(_resolveComponent("a-icon"), {
              "icon": item.icon
            }, null) : null, _createVNode("span", {
              "class": "sub-title"
            }, [item.name])];
          }
        });
      }

      var slots = {
        title: function title() {
          return _createVNode(_Fragment, null, [item.icon ? _createVNode(_resolveComponent("a-icon"), {
            "icon": item.icon
          }, null) : null, _createVNode("span", {
            "class": "title"
          }, [item.name])]);
        }
      };
      return _createVNode(_resolveComponent("el-sub-menu"), {
        "index": item.code
      }, _objectSpread({
        default: function _default() {
          return [item.children.map(function (subItem) {
            return _createVNode(_resolveComponent("a-nav-item"), {
              "item": subItem,
              "key": subItem.code
            }, null);
          })];
        }
      }, slots));
    };
  }
});