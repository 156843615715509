import { computed, ComputedRef } from 'vue'
import { useStore, mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex'
import { AnyFunction } from '@/interfaces'

type MapperValueResult = {[key: string]: ComputedRef<any> }
type MapperFuncResult = {[key: string]: AnyFunction }
type MapperResult = MapperValueResult | MapperFuncResult
type MapperArg = string | string[]

export function useState(...args): MapperValueResult {
  const isNamespaced = isWithNamespace(args)
  if (isNamespaced) {
    const mapFunc = createNamespacedHelpers(args[0]).mapState
    return useMapper(args[1], mapFunc, false) as MapperValueResult
  } else {
    return useMapper(args[0], mapState, false) as MapperValueResult
  }
}

export function useMutations(...args): MapperFuncResult {
  const isNamespaced = isWithNamespace(args)
  if (isNamespaced) {
    const mapFunc = createNamespacedHelpers(args[0]).mapMutations
    return useMapper(args[1], mapFunc, true) as MapperFuncResult
  } else {
    return useMapper(args[0], mapMutations, true) as MapperFuncResult
  }
}

export function useActions(...args): MapperFuncResult {
  const isNamespaced = isWithNamespace(args)
  if (isNamespaced) {
    const mapFunc = createNamespacedHelpers(args[0]).mapActions
    return useMapper(args[1], mapFunc, true) as MapperFuncResult
  } else {
    return useMapper(args[0], mapActions, true) as MapperFuncResult
  }
}

export function useGetters(...args): MapperValueResult {
  const isNamespaced = isWithNamespace(args)
  if (isNamespaced) {
    const mapFunc = createNamespacedHelpers(args[0]).mapGetters
    return useMapper(args[1], mapFunc, false) as MapperValueResult
  } else {
    return useMapper(args[0], mapGetters, false) as MapperValueResult
  }
}

function useMapper(mapper: string | string[], mapFunc: AnyFunction, isFunc: boolean): MapperResult {
  const store = useStore()
  const mappedFuncMap = mapFunc(mapper)
  const res: MapperResult = {}

  Object.keys(mappedFuncMap).forEach((key) => {
    const func = mappedFuncMap[key].bind({ $store: store })
    res[key] = isFunc ? func : computed(func)
  })

  return res
}

function isWithNamespace(args: MapperArg): boolean {
  return (args.length > 1) && (typeof args[0] === 'string')
}
