import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import MainLayout from '@/components/layout/main-layout/MainLayout.vue'
import NotFound from '@/views/error/not-found/NotFound.vue'
import Home from '../views/home/Home.vue'

NProgress.configure({
  showSpinner: false
})

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      }
    ]
  }, {
    path: '/403',
    name: 'no-permission',
    component: () => import('../views/error/no-permission/NoPermission.vue')
  }, {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next() // next({path: '/login'})
  NProgress.done()
})

export default router
