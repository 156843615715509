type StorageData = {[key: string]: any} | number | string | null

export class Storage {
  get(key: string): StorageData {
    let val = localStorage.getItem(key)
    if (!val) {
      return val
    }
    try {
      val = JSON.parse(val)
      return val
    } catch (err) {
      return val
    }
  }

  set(key: string, data: StorageData): void {
    const val = JSON.stringify(data)
    localStorage.setItem(key, val)
  }
}

export const storage = new Storage()
